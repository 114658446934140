import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';
import { Link } from 'react-router-dom';

function Blog() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 6;

    useEffect(() => {
        axios.get(`${BASE_URL}/blog`)
            .then(response => {
                if (response.data.status) {
                    setBlogs(response.data.blog);
                }
            })
            .catch(error => {
                console.error("Error fetching blog data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const truncateHtml = (html, maxLength = 100) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        const textContent = tempDiv.innerText || tempDiv.textContent;
        return textContent.length > maxLength ? textContent.substring(0, maxLength) + "..." : textContent;
    };

    // Pagination logic
    const totalPages = Math.ceil(blogs.length / blogsPerPage);
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Header />
            <main>
                <div className="about card mb-4 bg-light border-0">
                    <div className="card-body p-9 text-center">
                        <h1 className="mb-0">Blog</h1>
                        <p className="mb-0">Home / Blog</p>
                    </div>
                </div>
                <section className="mt-6 mb-lg-14 mb-8">
                    <div className="container">
                        {loading ? (
                            <p className="text-center">Loading blogs...</p>
                        ) : (
                            <>
                                <div className="row">
                                    {currentBlogs.map((blog) => (
                                        <div key={blog.id} className="col-12 col-md-6 col-lg-4 mb-10">
                                            <div className="mb-4">
                                                <Link to={`/blog-details/${blog.slug}`}>
                                                    <div className="img-zoom">
                                                        <img
                                                            src={blog.image ? `${BASE_IMAGE_URL}/${blog.image}` : '../assets/images/blog/default.jpg'}
                                                            alt={blog.title}
                                                            className="img-fluid w-100"
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div>
                                                <h2 className="h5">
                                                    <Link to={`/blog-details/${blog.slug}`} className="text-inherit">
                                                        {blog.title}
                                                    </Link>
                                                </h2>
                                                <p
                                                    className="mb-0 lead"
                                                    dangerouslySetInnerHTML={{
                                                        __html: truncateHtml(blog.description, 100),
                                                    }}
                                                ></p>
                                                <div className="d-flex justify-content-between text-muted mt-4">
                                                    <span><small>{new Date(blog.created_at).toDateString()}</small></span>
                                                    <span>
                                                        <Link to={`/blog-details/${blog.slug}`} className="text-muted small">
                                                            Read more
                                                        </Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Pagination */}
                                {totalPages > 1 && (
                                    <div className="row mt-8 pt-6">
                                        <div className="col">
                                            <nav>
                                                <ul className="pagination justify-content-center">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <button
                                                                className="page-link"
                                                                onClick={() => handlePageChange(currentPage - 1)}
                                                            >
                                                                &laquo; Previous
                                                            </button>
                                                        </li>
                                                    )}
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                            <button
                                                                className="page-link"
                                                                onClick={() => handlePageChange(index + 1)}
                                                            >
                                                                {index + 1}
                                                            </button>
                                                        </li>
                                                    ))}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} >
                                                                Next &raquo;
                                                            </button>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Blog;
