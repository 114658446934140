import React, { useEffect, useState } from 'react';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';

function About() {
    const [aboutData, setAboutData] = useState([]);

    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/about`);
                const data = await response.json();

                if (data.status) {
                    setAboutData(data.about);
                }
            } catch (error) {
                console.error('Error fetching about data:', error);
            }
        };

        fetchAboutData();
    }, []);

    return (
        <>
            <Header />
            <main>
                <div className="about card mb-4 bg-light border-0">
                    <div className="card-body p-9 text-center">
                        <h1 className="mb-0">About Us</h1>
                        <p className="mb-0">Home / About</p>
                    </div>
                </div>
                <section className="mt-lg-14 mt-8 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                {aboutData.map((section, index) => (
                                    <div
                                        key={section.id}
                                        className={`row mb-14`}
                                        style={{ display: 'flex', flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }}
                                    >

                                        <div className={`col-md-6 text-md-start about-page`}>
                                            <h1 className="mb-6">{section.title}</h1>
                                            <div
                                                className="mb-0 lead"
                                                dangerouslySetInnerHTML={{
                                                    __html: section.description,
                                                }}
                                            ></div>
                                        </div>


                                        <div className="col-md-6">
                                            <img
                                                src={`${BASE_IMAGE_URL}/${section.image}`}
                                                alt={section.title}
                                                className="img-fluid rounded image-hover"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default About;
