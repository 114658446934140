import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, Website_url } from '../apiConfig';
import Footer from '../Pages/Footer';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('base_url', `${Website_url}/reset-password`);

            const response = await axios.post(`${BASE_URL}/forget-password`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status) {
                // Store email in localStorage
                localStorage.setItem('resetEmail', email);
                toast.success('Reset link sent to your email.');
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="border-bottom shadow-sm">
                <nav className="navbar navbar-light py-2">
                    <div className="container justify-content-center justify-content-lg-between">
                        <a className="navbar-brand" href="/">
                            <img src="../assets/images/b2b/logo.png" alt="v2u" className="d-inline-block align-text-top" />
                        </a>
                        <span className="navbar-text">
                            Already have an account? <a href="/signin">Sign in</a>
                        </span>
                    </div>
                </nav>
            </div>

            <main>
                <section className="my-lg-14 my-8">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
                                <img src="../assets/images/svg-graphics/fp-g.svg" alt="" className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1 d-flex align-items-center">
                                <div>
                                    <div className="mb-lg-9 mb-5">
                                        <h1 className="mb-2 h2 fw-bold">Forgot your password?</h1>
                                        <p>Please enter the email address associated with your account, and we will email you a link to reset your password.</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-12 d-grid gap-2">
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Sending...' : 'Send Link'}
                                                </button>
                                                <a href="/signup" className="btn btn-light">Back</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer></Footer>
            <ToastContainer />
        </>
    );
}
