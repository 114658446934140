import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Aside from './Aside';
import { BASE_URL } from '../apiConfig';

function Address() {
    const [addresses, setAddresses] = useState([]);
    const [newAddress, setNewAddress] = useState({
        b_address_line1: '',
        b_address_line2: '',
        b_city: '',
        b_state: '',
        b_country: '',
        b_pincode: '',
        b_phone: '',
        is_default: 'n'
    });
    const [editingAddress, setEditingAddress] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [totalAddresses, setTotalAddresses] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        fetchAddresses(currentPage);
    }, [currentPage]);

    const fetchAddresses = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/billing-address-list`, { 
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            });

            setAddresses(response.data.data.billing_addresses.data);
            setTotalPages(response.data.data.billing_addresses.last_page);
            setTotalAddresses(response.data.data.billing_addresses.total);
            const perPage = response.data.data.billing_addresses.per_page;
            setPerPage(perPage)
            setLoading(false);
        } catch (error) {
            console.log(error);
            
            toast.error('Error fetching address list');
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAddress({ ...newAddress, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            if (editingAddress) {
                await axios.post(`${BASE_URL}/billing-address-update/${editingAddress.id}`, newAddress, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                    }
                });
                toast.success('Address updated successfully');
            } else {
                await axios.post(`${BASE_URL}/billing-address-store`, newAddress, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                    }
                });
                toast.success('Address added successfully');
            }
            fetchAddresses(currentPage);
            handleCloseModal();
        } catch (error) {
            toast.error('Error saving address');
        }
    };

    const handleEdit = (address) => {
        setEditingAddress(address);
        setNewAddress(address);
        window.bootstrap.Modal.getOrCreateInstance(document.getElementById('addAddressModal')).show();
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this address?')) {
            try {
                await axios.get(`${BASE_URL}/billing-address-delete/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                    }
                });
                toast.success('Address deleted successfully');
                fetchAddresses(currentPage);
            } catch (error) {
                toast.error('Error deleting address');
            }
        }
    };

    const handleCloseModal = () => {
        setNewAddress({
            b_address_line1: '',                                                    
            b_address_line2: '',                                                                                            
            b_city: '',                                                                                                         
            b_state: '',
            b_country: '',                                                                                                      
            b_pincode: '',                                                                                                  
            b_phone: '',                                                                                                                
            is_default: 'n'                                                                                                             
        });                                                                                                                             
        setEditingAddress(null);                                                                                                    
        window.bootstrap.Modal.getOrCreateInstance(document.getElementById('addAddressModal')).hide();                                                                                                                                        
    };                                                                                                                                        

    const handlePageChange = (page) => {                                                                                                                                                                                                                                                              
        if (page >= 1 && page <= totalPages) {                                                                                                                                                                                                                             
            setCurrentPage(page);                                                                                                                                                  
        }                                                                                                                                                                                   
    };                                                                                                                                             
                                                                                                                                               
    return (                                                                                                                                                        
        <>                                                                                                                                              
            <Header />                                                                                                                                                       
            <main>                                                                                                                                                      
                <section>                                                                                                            
                    <div className="container">                                                                                         
                        <div className="row"> 
                            <Aside />
                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="py-6 p-md-6 p-lg-10">
                                    <div className="d-flex justify-content-between align-items-center d-md-none py-4">
                                        <h3 className="fs-5 mb-0">Shipping Addresses</h3>
                                        <button className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAccount" aria-controls="offcanvasAccount">
                                            <i className="bi bi-text-indent-left fs-3" />                                   
                                        </button>                                                                       
                                    </div>                                                                              
                                    <div className="table-responsive-xxl border-0">                                         
                                        {loading ? (
                                            <p>Loading addresses...</p>
                                        ) : (
                                            <>
                                                <table className="table mb-0 text-nowrap table-centered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>SR No.</th>
                                                            <th>Address</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {addresses.map((address, index) => (
                                                            <tr key={index}>
                                                                <td className="align-middle border-top-0">{index + 1 + (currentPage - 1) * perPage}</td>
                                                                <td className="align-middle border-top-0">
                                                                    {address.b_address_line1}, {address.b_city}, {address.b_state}, {address.b_country}, {address.b_pincode}, ph: {address.b_phone}
                                                                </td>
                                                                <td className="text-muted align-middle border-top-0">
                                                                    <a href="#" className="text-inherit" onClick={() => handleEdit(address)}>Edit</a>
                                                                    <a href="#" className="text-danger ms-3" onClick={() => handleDelete(address.id)}>Delete</a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                {/* Pagination */}
                                                <div className="d-flex justify-content-between align-items-center mt-4">
                                                    <span>Showing {addresses.length} of {totalAddresses} addresses</span>
                                                    <nav aria-label="Page navigation">
                                                        <ul className="pagination mb-0">
                                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                                                            </li>
                                                            {[...Array(totalPages)].map((_, index) => (
                                                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                                                                </li>
                                                            ))}
                                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* Modal for Adding/Editing Address */}
            <div
                className="modal fade"
                id="addAddressModal"
                tabIndex={-1}
                aria-labelledby="addAddressModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-6">
                            <div className="d-flex justify-content-between mb-5">
                                <div>
                                    <h5 className="mb-1" id="addAddressModalLabel">
                                        {editingAddress ? 'Edit Shipping Address' : 'New Shipping Address'}
                                    </h5>
                                    <p className="small mb-0">
                                        {editingAddress ? 'Edit your shipping address.' : 'Add new shipping address for your order delivery.'}
                                    </p>
                                </div>
                                <div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Address Line 1"
                                        name="b_address_line1"
                                        value={newAddress.b_address_line1}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Address Line 2"
                                        name="b_address_line2"
                                        value={newAddress.b_address_line2}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        name="b_city"
                                        value={newAddress.b_city}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="State"
                                        name="b_state"
                                        value={newAddress.b_state}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Country"
                                        name="b_country"
                                        value={newAddress.b_country}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Pincode"
                                        name="b_pincode"
                                        value={newAddress.b_pincode}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Phone"
                                        name="b_phone"
                                        value={newAddress.b_phone}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <label className="form-check-label">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="is_default"
                                            checked={newAddress.is_default === 'y'}
                                            onChange={(e) => setNewAddress({ ...newAddress, is_default: e.target.checked ? 'y' : 'n' })}
                                        />
                                        Set as default address
                                    </label>
                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    {editingAddress ? 'Update Address' : 'Add Address'}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ms-3"
                                    data-bs-dismiss="modal"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
            <Footer />
        </>
    );
}

export default Address;
