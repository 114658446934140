const BASE_URL = 'https://admin.v2umart.krishnasoftweb.com/api';
const BASE_IMAGE_URL = 'https://admin.v2umart.krishnasoftweb.com';
const Website_url = "https://v2umart.krishnasoftweb.com";

// const BASE_URL = 'http://127.0.0.1:8000/api';   
// const BASE_IMAGE_URL = 'http://127.0.0.1:8000';
// const Website_url = "http://192.168.1.9:3000";

// const BASE_URL = 'https://admin.v2umart.com/api';
// const BASE_IMAGE_URL = 'https://admin.v2umart.com/';
// const Website_url = "https://v2umart.com";


// const BASE_URL = 'https://backend.v2umart.com/api';
// const BASE_IMAGE_URL = 'https://backend.v2umart.com';
// const Website_url = "https://frontend.v2umart.com";

export { BASE_URL, BASE_IMAGE_URL, Website_url };