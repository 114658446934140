import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Aside from './Aside';
import Model from './Model';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Setting() {
    const [accountDetails, setAccountDetails] = useState({
        name: '',
        email: '',
        phone: '',
        image: ''
    });
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        phone: '',
        image: ''
    });
    const [imagePreview, setImagePreview] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [profileErrors, setProfileErrors] = useState({});


    useEffect(() => {

        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        console.log(userDetails);
        if (userDetails) {
            setAccountDetails({
                name: userDetails.primary_contact_name || '',
                email: userDetails.email || '',
                phone: userDetails.phone || '',
                image: userDetails.image || ''
            });
            setProfile({
                name: userDetails.primary_contact_name || '',
                email: userDetails.email || '',
                phone: userDetails.phone || '',
                image: userDetails.image || ''
            });
        }
    }, []);

    const handleProfileChange = async (e) => {
        e.preventDefault();
        setLoading(true);
        setProfileErrors({});

        // Validate form
        const formErrors = {};
        if (!profile.name) formErrors.name = 'Name is required';
        if (!profile.email) formErrors.email = 'Email is required';
        if (!profile.phone) formErrors.phone = 'Phone number is required';
        if (Object.keys(formErrors).length > 0) {
            setProfileErrors(formErrors);
            setLoading(false);
            return;
        }

        try {
            // Create form data for profile update
            const formData = new FormData();
            formData.append('company_name', profile.name);
            formData.append('email', profile.email);
            formData.append('phone', profile.phone);
            if (profile.image) {
                formData.append('image', profile.image);
            }

            const response = await axios.post(`${BASE_URL}/customer-profile-update`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            });

            const { data } = response;

            if (data.status) {
                toast.success(data.message);
                setAccountDetails(profile);
                localStorage.setItem('userDetails', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('userDetails')),
                    primary_contact_name: profile.name,
                    email: profile.email,
                    phone: profile.phone,
                    image: profile.image
                }));
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error(error.response?.data?.message || 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfile({ ...profile, image: file });
            setImagePreview(URL.createObjectURL(file));
        }
    };
    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});

        // Validate form
        const formErrors = {};
        if (!currentPassword) formErrors.currentPassword = 'Current password is required';
        if (!newPassword) formErrors.newPassword = 'New password is required';
        if (newPassword.length < 8) formErrors.newPassword = 'New password must be at least 8 characters';
        if (newPassword !== confirmPassword) formErrors.confirmPassword = 'Passwords do not match';
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/customer-changepassword`, {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmPassword
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            });

            const { data } = response;

            if (data.status) {
                toast.success(data.message);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error changing password:', error);
            toast.error(error.response?.data?.message || 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center d-md-none py-4">
                                    <h3 className="fs-5 mb-0">Account Setting</h3>
                                    <button className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAccount" aria-controls="offcanvasAccount">
                                        <i className="bi bi-text-indent-left fs-3" />
                                    </button>
                                </div>
                            </div>
                            <Aside />
                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="py-6 p-md-6 p-lg-10">
                                    <div className="mb-6">
                                        <h2 className="mb-0">Account Setting</h2>
                                    </div>
                                    <div>
                                        <h5 className="mb-4">Account details</h5>
                                        <div className="row">
                                            {/* Form Section */}
                                            <div className="col-lg-6">
                                                <h5 className="mb-4">Update Profile</h5>
                                                <form onSubmit={handleProfileChange}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" className={`form-control ${profileErrors.name ? 'is-invalid' : ''}`} placeholder="Name" value={profile.name} onChange={(e) => setProfile({ ...profile, name: e.target.value })} />
                                                        {profileErrors.name && <div className="invalid-feedback">{profileErrors.name}</div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input type="email" className={`form-control ${profileErrors.email ? 'is-invalid' : ''}`} placeholder="Email" value={profile.email} onChange={(e) => setProfile({ ...profile, email: e.target.value })} />
                                                        {profileErrors.email && <div className="invalid-feedback">{profileErrors.email}</div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Phone</label>
                                                        <input type="text" className={`form-control ${profileErrors.phone ? 'is-invalid' : ''}`} placeholder="Phone number" value={profile.phone} onChange={(e) => setProfile({ ...profile, phone: e.target.value })} />
                                                        {profileErrors.phone && <div className="invalid-feedback">{profileErrors.phone}</div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Image</label>
                                                        <input type="file" className="form-control" accept="image/*" onChange={handleImageChange} />
                                                    </div>
                                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                                        {loading ? 'Saving Changes...' : 'Save Changes'}
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="col-lg-3 text-center">
                                            </div>
                                            {/* Profile Image Section */}
                                            <div className="col-lg-3 d-flex flex-column align-items-center">
                                                <h5 className="mb-4 text-center">Company Logo</h5>
                                                <div className="mb-4">
                                                    <img src={imagePreview || (accountDetails.image ? `${BASE_IMAGE_URL}/${accountDetails.image}` : 'default-avatar-url.jpg')}
                                                        alt="Company Logo"
                                                        className="rounded-circle"
                                                        width="295"
                                                        height="293">
                                                    </img>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr className="my-10" />
                                    <div className="pe-lg-14">
                                        <h5 className="mb-4">Change Password</h5>
                                        <form className="row row-cols-1 row-cols-lg-2" onSubmit={handlePasswordChange}>
                                            <div className="mb-3 col">
                                                <label className="form-label">Current Password</label>
                                                <input type="password" className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`} placeholder="**********" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                                                {errors.currentPassword && <div className="invalid-feedback">{errors.currentPassword}</div>}
                                            </div>
                                            <div className="mb-3 col">
                                                <label className="form-label">New Password</label>
                                                <input type="password" className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`} placeholder="**********" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                                            </div>
                                            <div className="mb-3 col">
                                                <label className="form-label">Confirm Password</label>
                                                <input type="password" className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} placeholder="**********" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                            </div>
                                            <div className="col-12 mt-5 pt-2">
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Changing Password...' : 'Change Password'}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <ToastContainer />
        </>
    );
}

export default Setting;
