import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';
import Header from './Header';
import Footer from './Footer';

function BlogDetails() {
    const { blogSlug } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    ;

    useEffect(() => {
        axios.get(`${BASE_URL}/blog-details/${blogSlug}`)
            .then(response => {
                if (response.data.status) {
                    setBlog(response.data.blog);
                } else {
                    setError("Blog not found");
                }
            })
            .catch(error => {
                console.error("Error fetching blog details:", error);
                setError("Failed to load blog details");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [blogSlug]);

    if (loading) {
        return <p className="text-center">Loading blog details...</p>;
    }

    if (error) {
        return <p className="text-center text-danger">{error}</p>;
    }

    return (
        <>
            <Header />
            <main>
                <section className="my-lg-14 my-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto ">
                                <div className="mb-5 text-center">
                                    <h1 className="fw-bold" style={{ fontSize: "3rem" }}>{blog.title}</h1>

                                    <div className="text-muted mt-4">
                                        <small>{new Date(blog.created_at).toDateString()}</small>
                                    </div>
                                </div>
                                <div className="mb-8">
                                    <img src={blog.image ? `${BASE_IMAGE_URL}/${blog.image}` : '../assets/images/blog/default.jpg'} alt={blog.title} className="img-fluid rounded" />
                                </div>
                                <div>
                                    <div className="mb-4" dangerouslySetInnerHTML={{ __html: blog.description }}></div>
                                    <hr className="mt-lg-10 mb-lg-6 my-md-6" />
                                    <hr className="mt-lg-6 mb-lg-10 my-md-6" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default BlogDetails;