import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { useParams } from 'react-router-dom';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';

export default function OrderSuccess() {
    const { orderNumber } = useParams();
    const [orderDetails, setOrderDetails] = useState({ order_items: [], payments: {} });
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/order-detail/${orderNumber}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                    },
                });
                console.log("API Response: ", response.data);   
                setOrderDetails(response.data.data.order);
            } catch (err) {
                console.error("Failed to fetch order details:", err);
                setError("Failed to load order details");
            }
        };

        fetchOrderDetails();
    }, [orderNumber]);

    if (error) return <div>{error}</div>;
    const subtotal = orderDetails.order_items.reduce((sum, item) => 
        sum + (item.product.discount_price * item.product_qty), 0);
    return (
        <>
            <Header />
            <main>
                <section className="mb-lg-14 mb-8 mt-8">
                    <div className="container">
                        {orderDetails ? (
                            <div className="row">
                                <div className="col-12 text-center mb-4">
                                    <h1 className="thankyou">Thank you!</h1>
                                    <p className="bg-light-success">Your order has been placed successfully. Thank you for your purchase!</p>
                                </div>
                                <div className="col-12 border border-1 mb-4">
                                    <h1 className="mt-2">Order Details</h1>
                                    <div className="row">
                                        <div className="col-6 mt-2">
                                            <span className="d-block mb-2">ORDER NUMBER: <strong>#{orderDetails.order_number}</strong></span>
                                            <span className="d-block mb-2">DATE: <strong>{new Date(orderDetails.created_at).toLocaleDateString()}</strong></span>
                                            <span className="d-block mb-2">TOTAL: <strong>{orderDetails.total_amount} INR</strong></span>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <span className="d-block mb-2">Customer Name: <strong>{orderDetails.customer_name}</strong></span>
                                            <span className="d-block mb-2">Phone: <strong>{orderDetails.customer_phone}</strong></span>
                                            <span className="d-block mb-2">Email: <strong>{orderDetails.customer_email}</strong></span>
                                            <span className="d-block mb-2">Customer GST: <strong>{orderDetails.customer?.gst}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 border border-1 mb-4">
                                    <h1 className="mt-2">Address</h1>
                                    <div className="row">
                                        <div className="col-6 mt-2">
                                            <h6>Shipping Address</h6>
                                            <address>
                                                <strong>{orderDetails.s_address_line1}</strong>
                                                <br />
                                                {orderDetails.s_address_line2}, {orderDetails.s_city}, {orderDetails.s_state}, {orderDetails.s_country}
                                                <br />
                                                <abbr title="Phone">P: {orderDetails.s_contact}</abbr>
                                            </address>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <h6>Billing Address</h6>
                                            <address>
                                                <strong>{orderDetails.b_address_line1}</strong>
                                                <br />
                                                {orderDetails.b_address_line2}, {orderDetails.b_city}, {orderDetails.b_state}, {orderDetails.b_country}
                                                <br />
                                                <abbr title="Phone">P: {orderDetails.b_contact}</abbr>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 border border-1 mb-4">
                                    <h1 className="mt-2">Payment Details</h1>
                                    <div className="row">
                                        <div className="col-6 mt-2">
                                            <span className="d-block mb-2">Payment Method: <strong>{orderDetails.payments?.payment_method || 'N/A'}</strong></span>
                                            <span className="d-block mb-2">Transaction ID: <strong>{orderDetails.payments?.txn_id || 'N/A'}</strong></span>
                                            {/* <span className="d-block mb-2">Payment Status: <strong>{orderDetails.payments?.payment_status || 'N/A'}</strong></span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 border border-1 mb-4">
                                    <h1 className="mt-2">Products</h1>
                                    <ul className="list-group list-group-flush">
                                        {orderDetails.order_items.length > 0 ? (
                                            orderDetails.order_items.map((item, index) => (
                                                <li key={index} className="list-group-item px-4 py-3">
                                                    <div className="row align-items-center">
                                                        <div className="col-2 col-md-2">
                                                            <img src={`${BASE_IMAGE_URL}/${item.product.primary_image}`} alt='product' className="img-fluid" width={50} />
                                                        </div>
                                                        <div className="col-5 col-md-5">
                                                            <h6 className="mb-0">{item.product.product_name}</h6>
                                                        </div>
                                                        <div className="col-2 col-md-2 text-center text-muted">
                                                            <span>{item.product_qty}</span>
                                                        </div>
                                                        {item.product.custom_rates && item.product.custom_rates.length > 0 ? (
                                                            <div className="col-3 text-lg-end text-start text-md-end col-md-3">
                                                                <span className="fw-bold">{item.product.custom_rates[0].custom_rate} INR</span>
                                                            </div>
                                                        ) : (
                                                            <div className="col-3 text-lg-end text-start text-md-end col-md-3">
                                                                <span className="fw-bold">{item.product.discount_price} INR</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="list-group-item">No items found.</li>
                                        )}
                                    </ul>
                                </div>
                                <div className="col-12 border border-1 p-4 mb-4">
                                    <h1 className="mt-2">Order Summary</h1>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><strong>Subtotal</strong></td>
                                                <td className="text-end">{subtotal.toFixed(2)} INR</td>
                                            </tr>
                                            <tr>
                                                <td><strong>CGST</strong></td>
                                                <td className="text-end">{orderDetails.cgst} INR</td>
                                            </tr>
                                            <tr>
                                                <td><strong>SGST</strong></td>
                                                <td className="text-end">{orderDetails.sgst} INR</td>
                                            </tr>
                                            <tr>
                                                <td><strong>IGST</strong></td>
                                                <td className="text-end">{orderDetails.igst} INR</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Grand Total</strong></td>
                                                <td className="text-end fw-bold">{orderDetails.total_amount} INR</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end mt-4 float-right">
                                    <a href="/" className="btn btn-primary">Continue Shopping</a>
                                </div>
                            </div>
                        ) : (
                            <p>Loading order details...</p>
                        )}
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
